import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";
import axios from 'axios';



const GeneratePage = () => {
  const [inserText, setInsertText] = useState("");
  const [iosText, setIosText] = useState("");
  const [result, setResult] = useState(null);

  return <div style={{ backgroundColor: "#171E20", position: "relative", minHeight: "100vh" }}>
    <div style={{ position: "absolute", paddingTop: "25px", paddingBottom: "165px", bottom: "0", top: "0", left: "0", right: "0", margin: "auto", height: "200px", textAlign: "center", backgroundColor: "black", marginLeft: "15px", marginRight: "15px", borderRadius: "25px" }}>
      <img style={{ width: "80px", marginBottom: "25px" }} src="/logo.png"></img><br />
      <input onChange={e => { setInsertText(e.target.value) }} style={{ width: "250px", padding: "10px", borderRadius: "8px", outline: "none", border: "0", backgroundColor: "#eaeaea" }} type="text" placeholder="es. https://www.instagram.com/p/CUP2HtiqC1X/"></input>
      <br></br><input onChange={e => { setIosText(e.target.value) }} style={{ marginTop: "25px", width: "250px", padding: "10px", borderRadius: "8px", outline: "none", border: "0", backgroundColor: "#eaeaea" }} type="text" placeholder="es. https://news.outbrief.it/Sn3u/ or Sn3u"></input>

      <br />
      <button onClick={() => {
        let b = inserText.replace("?utm_medium=copy_link", "");
        let a;
        if (b.slice(-1) == "/") {
          a = b.substring(0, b.length - 1);
        }
        const final = (a && a.length > 0) ? a.replace("?utm_medium=copy_link", "").substr(a.lastIndexOf('/') + 1) : b.replace("?utm_medium=copy_link", "").substr(b.lastIndexOf('/') + 1);
        console.log(final)
        let iosID = iosText.replace("https://news.outbrief.it/", "").replace("/", "");
        setResult(`${final}/${iosID}`);
      }} style={{ width: "270px", border: "0", padding: "10px 20px", borderRadius: "15px", backgroundColor: "black", color: "white", cursor: "pointer", border: "1px solid white", fontWeight: "bold", display: "block", margin: "0 auto", marginTop: "25px" }}>Create link</button>
      <p style={{ color: "white" }}>Generated link:<br /> {result && <a style={{ color: "white" }} href={`https://link.outbrief.it/post/${result}`}>{`https://link.outbrief.it/post/${result}`}</a>}</p>
    </div>
  </div>;
}

const LinkedPosts = (props) => {
  const params = useParams();
  const [result, setResult] = useState(null);
  const [loader, setLoader] = useState(true);

  function iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  const getMediaID = async () => {
    const result = await axios.get(`https://us-central1-deep-link-6175c.cloudfunctions.net/helloWorld?id=${params.id}`);
    if (result.data) {
      setResult(result.data);
      setLoader(false);
      (iOS() && !params.deepId) ?
        document.getElementById("link-ios-old").click() : (iOS() && params.deepId) ? document.getElementById("link-ios").click() : document.getElementById("link-android").click();
    }
  }
  useEffect(async () => {
    await getMediaID();
  }, [])
  return <div style={{ minHeight: "100vh", backgroundColor: "#171E20" }}>
    <div style={{ textAlign: "center", paddingTop: "125px" }}>
      <img style={{ width: "100px" }} src="/logo.png"></img><br />
      <img style={{ width: "120px" }} src="/loader.gif"></img>
    </div>
    <a style={{ display: "none" }} id="link-android" href={`instagram://media?id=${params.id}`} p> Android IG</a>
    <a style={{ display: "none" }} id="link-ios" href={`https://news.outbrief.it/${params.deepId}`}> Iphone IG</a>
    <a style={{ display: "none" }} id="link-ios-old" href={`instagram://media?id=${result}`}> Iphone</a>

  </div>;
}
function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<GeneratePage />} />
          <Route path="/post/:id" element={<LinkedPosts />} />
          <Route path="/post/:id/:deepId" element={<LinkedPosts />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
